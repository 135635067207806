import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography, CircularProgress, Box, Snackbar, Alert, Autocomplete } from '@mui/material';

function App() {
  const [token, setToken] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [guilds, setGuilds] = useState([]);
  const [channels, setChannels] = useState([]);
  const [selectedGuild, setSelectedGuild] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [embed, setEmbed] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_WEBSITE_URL}:${process.env.REACT_APP_API_PORT}/login`,
        { token },
        { withCredentials: true }
      );
      setLoggedIn(true);
      setSnackbar({ open: true, message: `Logged in to ${response.data.botName}`, severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: error.response?.data || 'Login failed', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchChannels = useCallback(async (guildId) => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_WEBSITE_URL}:${process.env.REACT_APP_API_PORT}/channels`, { guildId }, { withCredentials: true });
      setChannels(data);
      if (data.length > 0) {
        setSelectedChannel(data[0]);
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching channels', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchGuilds = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_WEBSITE_URL}:${process.env.REACT_APP_API_PORT}/guilds`, { withCredentials: true });
      setGuilds(data);
      if (data.length > 0) {
        setSelectedGuild(data[0]);
        fetchChannels(data[0].id);
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching guilds', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [fetchChannels]);

  useEffect(() => {
    if (loggedIn) {
      fetchGuilds();
    }
  }, [loggedIn, fetchGuilds]);

  const handleGuildChange = async (event, newValue) => {
    setSelectedGuild(newValue);
    if (newValue) {
      await fetchChannels(newValue.id);
    } else {
      setChannels([]);
      setSelectedChannel(null);
    }
  };

  const handleChannelChange = (event, newValue) => {
    setSelectedChannel(newValue);
  };

  const handleSendEmbed = async () => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_WEBSITE_URL}:${process.env.REACT_APP_API_PORT}/send_embed`, {
        guildId: selectedGuild.id,
        channelId: selectedChannel.id,
        embed
      }, { withCredentials: true });
      setSnackbar({ open: true, message: 'Embed sent successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: error.response?.data || 'Error sending embed', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_WEBSITE_URL}:${process.env.REACT_APP_API_PORT}/logout`, {}, { withCredentials: true });
      setLoggedIn(false);
      setToken('');
      setGuilds([]);
      setChannels([]);
      setSelectedGuild(null);
      setSelectedChannel(null);
      setEmbed('');
      setSnackbar({ open: true, message: 'Logged out successfully', severity: 'info' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error logging out', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Discord Embed Sender</Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {!loggedIn && (
          <>
            <TextField 
              label="Bot Token" 
              type="password" 
              value={token} 
              onChange={(e) => setToken(e.target.value)} 
              fullWidth 
            />
            <Button variant="contained" color="primary" onClick={handleLogin} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Login'}
            </Button>
          </>
        )}
        {loggedIn && (
          <>
            <Button variant="contained" color="secondary" onClick={fetchGuilds} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Refresh Guilds'}
            </Button>
            <Autocomplete
              value={selectedGuild}
              onChange={handleGuildChange}
              options={guilds}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => <TextField {...params} label="Select Guild" fullWidth />}
              disabled={loading}
            />
            {channels.length > 0 && (
              <Autocomplete
                value={selectedChannel}
                onChange={handleChannelChange}
                options={channels}
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => <TextField {...params} label="Select Channel" fullWidth />}
                disabled={loading}
              />
            )}
            <TextField label="Embed JSON" value={embed} onChange={(e) => setEmbed(e.target.value)} fullWidth multiline rows={10} />
            <Button variant="contained" color="primary" onClick={handleSendEmbed} disabled={loading || !selectedChannel || !embed}>
              {loading ? <CircularProgress size={24} /> : 'Send Embed'}
            </Button>
            <Button variant="contained" color="inherit" onClick={handleLogout} disabled={loading}>
              Log Out
            </Button>
            <Button variant="contained" color="info" href="https://discohook.org/" target="_blank" disabled={loading}>
              Generate JSON Files
            </Button>
            <Button variant="contained" color="success" href="https://discord.gg/G2NaXqP2kR" target="_blank" disabled={loading}>
              Join our Discord Server
            </Button>
          </>
        )}
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default App;
